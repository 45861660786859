<template>
  <section class="hero">
      <div class="hero__content container">
        <div class="hero__content--info">
          <h1 class="hero__title --fade-in-up" data-vertical-ivp-class="--animate">
            The Pharmacy of the Future
          </h1>
          <p class="hero__description --fade-in-up" data-vertical-ivp-class="--animate">
            Smart Rx Systems (SRXS) is the company behind the disruptive Smart PharmAssist™ Kiosk delivering
            exciting new robotic technology revealing the Pharmacy of the Future at the point of care for
            the convenience of the patient that will be able to give people access to multilingual pharmacy
            services 24-hours a day, seven days a week with 99.9% accuracy.
            <br> <br>
            This technology is more cost-effective than a full pharmacy, and can be deployed faster, more efficiently,
            and in places traditional pharmacy's can't be built.
            <br><br>
            The best part? The business model is already proven.
          </p>
          <div class="hero__btn --fade-in-up" data-vertical-ivp-class="--animate">
            <a href="https://smartrx.app.dealmaker.tech/invitations/2999f2c7-9fd9-4c02-a824-5d7a5b026f4d/view?tnames=srax" target="_blank">
              <button class="button">
                Invest Now
              </button>
            </a>
          </div>
        </div>
        <iframe
          src="https://player.vimeo.com/video/130198718?h=6d718a217e&portrait=0&byline=0&title=0"
          width="620"
          height="290"
          frameborder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowfullscreen>
        </iframe>
      </div>
    </section>
  </template>
  
  <script>

  export default {
    name: 'Hero',
    data () {
      return {
      }
    },
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style lang="scss" scoped>
  @import "../styles/components/hero.scss";
  </style>
  