<template>
  <section class="disclaimer">
      <div class="disclaimer__content container">
        <div class="disclaimer__text">
          <button v-on:click="disclaimer = !disclaimer">See Disclaimer</button>
          <transition name="slide-fade">
            <p v-if="!disclaimer">
              <b>Disclaimer</b>
              <br><br>
              Please read the Offering Circular here:
              <a target="_blank" href="https://www.sec.gov/Archives/edgar/data/1672227/000110465922119855/tm2228359d1_253g2.htm">Get Offering Circular</a>
              <br><br>
              THE OFFERING MATERIALS MAY CONTAIN FORWARD-LOOKING STATEMENTS AND INFORMATION RELATING TO,
              AMONG OTHER THINGS, THE COMPANY, ITS BUSINESS PLAN AND STRATEGY, AND ITS INDUSTRY. THESE
              FORWARD-LOOKING STATEMENTS ARE BASED ON THE BELIEFS OF, ASSUMPTIONS MADE BY, AND INFORMATION
              CURRENTLY AVAILABLE TO THE COMPANY'S MANAGEMENT. WHEN USED IN THE OFFERING MATERIALS,
              THE WORDS “ESTIMATE,” “PROJECT,” “BELIEVE,” “ANTICIPATE,” “INTEND,” “EXPECT” AND SIMILAR EXPRESSIONS
              ARE INTENDED TO IDENTIFY FORWARD-LOOKING STATEMENTS, WHICH CONSTITUTE FORWARD LOOKING STATEMENTS.
              THESE STATEMENTS REFLECT MANAGEMENT'S CURRENT VIEWS WITH RESPECT TO FUTURE EVENTS AND ARE SUBJECT TO RISKS
              AND UNCERTAINTIES THAT COULD CAUSE THE COMPANY'S ACTUAL RESULTS TO DIFFER MATERIALLY FROM THOSE CONTAINED
              IN THE FORWARD-LOOKING STATEMENTS. INVESTORS ARE CAUTIONED NOT TO PLACE UNDUE RELIANCE ON THESE FORWARD-LOOKING
              STATEMENTS, WHICH SPEAK ONLY AS OF THE DATE ON WHICH THEY ARE MADE. THE COMPANY DOES NOT UNDERTAKE ANY
              OBLIGATION TO REVISE OR UPDATE THESE FORWARD-LOOKING STATEMENTS TO REFLECT EVENTS OR CIRCUMSTANCES AFTER
              SUCH DATE OR TO REFLECT THE OCCURRENCE OF UNANTICIPATED EVENTS.
              <br><br>
              AN OFFERING STATEMENT REGARDING THIS OFFERING HAS BEEN FILED WITH THE SEC. THE SEC HAS QUALIFIED
              THAT OFFERING STATEMENT, WHICH ONLY MEANS THAT THE COMPANY MAY MAKE SALES OF THE SECURITIES DESCRIBED
              BY THE OFFERING STATEMENT. IT DOES NOT MEAN THAT THE SEC HAS APPROVED, PASSED UPON THE MERITS OR PASSED
              UPON THE ACCURACY OR COMPLETENESS OF THE INFORMATION IN THE OFFERING STATEMENT. YOU MAY OBTAIN A COPY OF
              THE OFFERING CIRCULAR THAT IS PART OF THAT OFFERING STATEMENT FROM:
              <br><br>
              <a target="_blank" href="https://www.sec.gov/Archives/edgar/data/1672227/000110465922119855/tm2228359d1_253g2.htm">
                https://www.sec.gov/Archives/edgar/data/1672227/000110465922119855/tm2228359d1_253g2.htm
              </a>
              <br><br>
              YOU SHOULD READ THE OFFERING CIRCULAR BEFORE MAKING ANY INVESTMENT.
            </p>
          </transition>
        </div>
        
      </div>
  </section>
</template>

<script>
export default {
  name: 'disclaimer',
  data () {
    return {
      disclaimer: true
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "../styles/components/disclaimer.scss";
</style>
