<template>
  <section class="considerations">
    <div class="considerations__content container">
      <div class="considerations__terms">
        <h1 class="considerations__title --key-title --fade-in-right" data-vertical-ivp-class="--animate">Major Investment Considerations</h1>
        <div class="considerations__information --fade-in-right --delay-1" data-vertical-ivp-class="--animate">
          <div class="--image">
            <img src="../assets/considerations/img-1.jpg">
          </div>
            <div class="--text">
            <h2>1. Uplisting Imminent</h2>
            <p class="considerations__description">
              Upon a successful Reg A Offering, we plan on uplisting to NYSE or Nasdaq, ASAP. 
              This will boost our visibility and get our shareholders paid sooner rather than later. 
              It really is a unique opportunity.
            </p>
          </div>
        </div>
        <div class="considerations__information --fade-in-right --delay-1" data-vertical-ivp-class="--animate">
          <div class="--image">
            <img src="../assets/considerations/img-2.jpg">
          </div>
            <div class="--text">
            <h2>2. Massively Disruptive</h2>
            <p class="considerations__description">
              It bears repeating that this technology is disruptive. 
              Not only is it more accurate than retail pharmacies, it is cheaper, smaller, 
              and more efficient. Add our patents and trademarks into the mix, and competitors 
              will have a large barrier to entry, keeping us relatively blue ocean for some time.
            </p>
          </div>
        </div>
        <div class="considerations__information --fade-in-right --delay-1" data-vertical-ivp-class="--animate">
          <div class="--image">
            <img src="../assets/considerations/img-3.jpg">
          </div>
            <div class="--text">
            <h2>3. Other Opportunities</h2>
            <p class="considerations__description">
              While deploying our Smart Kiosks in rural areas and underserved markets is a main strategy,
                there is also plenty of opportunity for other marketplacements. 
                Private practices could install a Kiosk for revenue sharing, there are 
                telemedicine partnerships we could pursue, and that’s just a few. 
                Based on demand, we have options.
            </p>
          </div>
        </div>
      </div>
      <div class="considerations__button --fade-in-up" data-vertical-ivp-class="--animate">
        <div class="btn">
          <a href="https://smartrx.app.dealmaker.tech/invitations/2999f2c7-9fd9-4c02-a824-5d7a5b026f4d/view?tnames=srax"
          target="_blank">
            <button class="button">
              Invest Now
            </button>
          </a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'considerations',
  data () {
    return {
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "../styles/components/considerations.scss";
</style>
