<template>
  <section class="invest">
    <div class="invest__content container">
      <h1 class="invest__title --fade-in-up" data-vertical-ivp-class="--animate">
        Why Invest in Smart Rx?
      </h1>
      <div class="invest__card-container">
        <div class="card --fade-in-up --delay-1" data-vertical-ivp-class="--animate">
          <div class="card__icon">
            <img src="../assets/invest/kiosk.png" alt="Kiosk Icon">
          </div>
          <h3 class="card__title">
            Proven Model
          </h3>
          <p class="card__description">
            The Smart Kiosk has been operational in several locations for over 3 years.
            The technology works, is compliant, and saves time and money. These systems
            create revenue, we just need capital to expand.
          </p>
        </div>
        <div class="card --fade-in-up --delay-2" data-vertical-ivp-class="--animate">
          <div class="card__icon">
            <img src="../assets/invest/tech.png" alt="Tech Icon">
          </div>
          <h3 class="card__title">
            Disruptive Technology
          </h3>
          <p class="card__description">
            The Smart Kiosk “Pharmacy-in-a-Box,” is an entirely automated system delivering exciting
            new robotic technology revealing the Pharmacy of the Future at the point of care for the
            convenience of the patient with override capability to manually control the dispensing of
            medication by a pharmacist. The current model has the capacity to dispense at least 225
            different types of medications with approximately 70 prescriptions filled of each type of
            the 225 medications, totaling 15,750 prescriptions and over-the-counter, or the OTC, medications
            at 99.9% accuracy (94.5% is the current pharmacy average).
          </p>
        </div>
        <div class="card --fade-in-up --delay-3" data-vertical-ivp-class="--animate">
          <div class="card__icon">
            <img src="../assets/invest/hyper.png" alt="Hyper Icon">
          </div>
          <h3 class="card__title">
            Hyper Efficient
          </h3>
          <p class="card__description">
            Kiosks can be opened for about $250K (compared to several million for a standard brick
            and mortar pharmacy), and for a micro-pharmacy for about for as little as $90K-$120K with
            about 150sqft of space and have lower operating costs due to less expensive personnel,
            less inventory and more predictable costs.
            <br><br>
            The size of the technology also allows our Kiosks to be deployed in underserved areas
            (like Dollar Stores and convenience stores) that other pharmacies can't build in due to cost and land.
          </p>
        </div>
      </div>
      <div class="invest__btn --fade-in-up --delay-1" data-vertical-ivp-class="--animate">
        <a href="https://smartrx.app.dealmaker.tech/invitations/2999f2c7-9fd9-4c02-a824-5d7a5b026f4d/view?tnames=srax" target="_blank">
          <button class="button">
            Invest Now
          </button>
        </a>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
  @import '@/styles/components/invest.scss';
</style>

<script>

export default {
  name: 'Invest',
  components: {
    
  },
  data() {
    return {
    };
  },
};
</script>