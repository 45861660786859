<template>
  <section class="vision">
      <div class="vision__content">
        <div class="vision__image --fade-in-left --delay-1" data-vertical-ivp-class="--animate">
          <img src="../assets/vision/vision.png">
        </div>
        <div class="vision__text">
          <h1 class="vision__title --fade-in-right --delay-1" data-vertical-ivp-class="--animate">
            Vision
          </h1>
          <p class="vision__description --fade-in-right --delay-2" data-vertical-ivp-class="--animate">
            At Smart Rx Systems Inc., we believe the world is at the cusp of a healthcare revolution,
            and innovation is the key. For over a decade, our core business has been defined by innovation,
            and we focus on creating solutions that incorporate resources from multiple industries to develop
            new technologies and cutting-edge products.
            <br><br>
            We invite you to join us in embracing this innovative revolution. Our vision is to make a positive
            impact on people's lives around the world, and through our innovative technologies, we aim to be the
            first and best choice for our customers. We strive to be one of the top healthcare companies globally,
            dedicating ourselves to providing new solutions and innovations for the future. Our goal is to transform
            healthcare into a modern, efficient and quality-driven system, reducing costs and improving quality
            across the industry more effectively and efficiently.
            <br><br>
            Join us on this exciting journey and be a part of shaping the future of healthcare.
          </p>
          <div class="vision__btn --fade-in-right" data-vertical-ivp-class="--animate">
            <a href="https://smartrx.app.dealmaker.tech/invitations/2999f2c7-9fd9-4c02-a824-5d7a5b026f4d/view?tnames=srax" target="_blank">
              <button class="button">
                Invest Now
              </button>
            </a>
          </div>
        </div>
      </div>
      <cta/>
  </section>
</template>

<script>

export default {
  name: 'Vision',
  data () {
    return {
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "../styles/components/vision.scss";
</style>
