<template>
  <header class="main-menu">
    <div class="container">
      <section class="main-menu__logo">
        <a href="/" class="logo">
          <img src="../assets/logo.png">
        </a>
      </section>
      <section class="main-menu__link">
        <a href="https://smartrx.app.dealmaker.tech/invitations/2999f2c7-9fd9-4c02-a824-5d7a5b026f4d/view?tnames=srax" target="_blank">
          <button class="button">Invest Now</button>
        </a>
      </section>
    </div>
  </header>
</template>

<style lang="scss" scoped>
  @import '@/styles/components/menu.scss';
</style>

<script>

export default {
  name: 'menu',
  data() {
  },
  methods: {
  },
};
</script>