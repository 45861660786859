<template>
    <section class="cta">
      <div class="cta__content container">
        <div class="cta__button --fade-in-up" data-vertical-ivp-class="--animate">
          <div class="btn">
            <a href="https://smartrx.app.dealmaker.tech/invitations/2999f2c7-9fd9-4c02-a824-5d7a5b026f4d/view?tnames=srax"
            target="_blank">
              <button class="button">
                Invest Now
              </button>
            </a>
          </div>
        </div>
      </div>
    </section>
  </template>
  
  <script>
  export default {
    name: 'cta',
    data () {
      return {
      }
    },
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style lang="scss" scoped>
  @import "../styles/components/cta.scss";
  </style>
  