<template>
    <section class="offer">
        <div class="offer__content container">
          <div class="offer__text">
            <h1 class="offer__title --fade-in-left" data-vertical-ivp-class="--animate">The Offer</h1>
            <p class="offer__description --fade-in-left --delay-1" data-vertical-ivp-class="--animate">
                Smart Rx is offering an Offering Amount of $50,000,000 comprised 
                of $45,000,000 of our Series REG A shares, and $5,000,000 of our 
                Class REG A shares. Each share of Series REG A, with an offering price 
                of $10.00 per share, has a Stated Value of $12.50 per share, and a 
                Redemption Value of $12.50 per share.
            </p>
            <p class="offer__description --fade-in-left --delay-1" data-vertical-ivp-class="--animate">
                The offering price of the Class REG A shares is to be $10.00 per share.
            </p>
          </div>
          <div class="offer__image--mobile">
            <img src="../assets/offer/img-1.jpg" alt="">
          </div>
        </div>
    </section>
  </template>
  
  <script>
  export default {
    name: 'offer',
    data () {
      return {
      }
    },
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style lang="scss" scoped>
  @import "../styles/components/offer.scss";
  </style>
  