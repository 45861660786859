<template>
  <main id="home">
    <hero />
    <invest />
    <opportunity />
    <vision />
    <considerations />
    <offer />
    <cta />
    <disclaimer/>
  </main>
</template>

<style lang="scss" scoped>
  @import '../styles/pages/home.scss';
</style>

<script>
// @ is an alias to /src
import hero from '@/components/hero.vue';
import invest from '@/components/invest.vue';
import opportunity from '@/components/opportunity.vue';
import vision from '@/components/vision.vue';
import considerations from '@/components/considerations.vue';
import offer from '@/components/offer.vue';
import cta from '@/components/cta.vue';
import disclaimer from '@/components/disclaimer.vue';

export default {
  name: 'Home',
  data() {
    return {
    };
  },
  components: {
    considerations,
    offer,
    cta,
    hero,
    invest,
    opportunity,
    vision,
    disclaimer
  },
  mounted() {
  },
};
</script>
