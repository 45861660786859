<template>
  <section class="opportunity">
      <div class="opportunity__content container">
        <div class="opportunity__text">
          <h1 class="opportunity__title --fade-in-right --delay-1" data-vertical-ivp-class="--animate">
            A Profitable Opportunity that Serves Neglected Communities
          </h1>
          <p class="opportunity__description --fade-in-right --delay-2" data-vertical-ivp-class="--animate">
            With the proceeds from this Offering and additional institutional placements and loans, the company
            plans to open over 196 new POC pharmacies and Micro-Pharmacies within 12 to 15 months. This will
            enable us to properly staff and provide all necessary services to the locations and their customers.
            We also aim to increase our footprint by potentially opening pharmacies or Micro-Pharmacies within
            chain stores, emergency medical centers, clinics, and large medical office buildings.
            <br><br>
            This isn't just guesswork. Our vision is to establish a network of seven Micro Pharmacies, supported
            by a Parent pharmacy and advanced technology solutions such as video communications and enterprise
            software. This approach is expected to be the quickest route to profitability in our business.
            Furthermore, we have launched a mail-order closed-door and compounding pharmacy that will allow us to
            serve customers across the United States. The same unit will also support our e-commerce site,
            offering over-the-counter medicine, vitamins, and durable medical equipment for sale.
            <br><br>
            And with each Smart Kiosk installed, people will benefit from faster medicine delivery,
            with more accuracy than industry standard. It's a win-win.
          </p>
        </div>
        <div class="opportunity__image --fade-in-left --delay-1" data-vertical-ivp-class="--animate">
          <img src="../assets/opportunity/opportunity.png">
        </div>
      </div>
      <cta/>
  </section>
</template>

<script>

export default {
  name: 'opportunity',
  data () {
    return {
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "../styles/components/opportunity.scss";
</style>
