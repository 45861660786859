<template>
  <footer class="footer">
    <section class="footer__content container">
      <div class="footer__company">
        <a href="#" class="logo">
          <img src="../assets/footer/smartrx_logo.svg">
        </a>
        <div class="footer__company-about">
					<a href="https://www.smartrxsystems.com/about-us/" target="_blank">
            <span class="bold-text">About</span>
          </a>
				</div>
        <div class="footer__company-about">
					<a class="link" href="https://www.smartrxsystems.com/" target="_blank">Internal Page</a>
					<a class="link" href="https://www.smartrxsystems.com/contact-us/" target="_blank">Contact</a>
        </div>
        <div class="footer__company-social">
          <a href="https://www.facebook.com/smartrxsys" target="_blank">
            <img src="../assets/social/facebook.svg">
          </a>
          <a href="https://twitter.com/SmartRxSystem" target="_blank">
            <img src="../assets/social/twitter.svg">
          </a>
          <a href="https://www.linkedin.com/company/smartrxsys/" target="_blank">
            <img src="../assets/social/linkedin.svg">
          </a>
          <a href="https://www.instagram.com/smartrxsys/" target="_blank">
            <img src="../assets/social/instagram.svg">
          </a>
        </div>
      </div>
      <div class="footer__microcaps">
        <div class="footer__microcaps-legal">
          <a href="https://microcaps.com/" class="logo">
            <img src="../assets/microcaps.svg">
          </a>
          <a href="https://microcaps.com/privacy-policy/" class="link" target="_blank">
            Privacy Policy
          </a>
          <a href="https://microcaps.com/contact-us/" class="link" target="_blank">
            Contact Us
          </a>
          <a href="https://microcaps.com/cookie-policy/" class="link" target="_blank">
            Cookie Policy
          </a>
        </div>
        <div class="footer__microcaps-social">
          <div class="footer__microcaps-social--links">
            <a href="https://www.facebook.com/microcapscom" target="_blank">
              <img src="../assets/social/m-facebook.png">
            </a>
            <a href="https://www.linkedin.com/company/microcapscom/" target="_blank">
              <img src="../assets/social/m-linkedin.png">
            </a>
            <a href="https://twitter.com/microcapsnews" target="_blank">
              <img src="../assets/social/m-twitter.png">
            </a>
          </div>
          <a href="https://srax.com/" class="link" target="_blank">
            About SRAX
          </a>
          <a href="https://mysequire.com/" class="link" target="_blank">
            About Sequire
          </a>
          <p class="copyright">
            © Microcaps.com is a trademark of SRAX, Inc.
          </p>
        </div>
      </div>
    </section>
  </footer>
</template>

<style lang="scss" scoped>
  @import '@/styles/components/footer.scss';
</style>

<script>
export default {
  name: 'Footer',
  data() {
    return {
    };
  },
};
</script>